import Markdown from 'markdown-to-jsx';

const MarkdownSpanOverride = ({ children }) => <span>{children}</span>;
// only allow emphasis https://github.com/probablyup/markdown-to-jsx/blob/master/fixture.md
const Marked = ({ children }) => (
  <Markdown
    options={{
      disableParsingRawHTML: true, // disable parsing html tags https://github.com/probablyup/markdown-to-jsx#optionsdisableparsingrawhtml
      overrides: {
        h1: MarkdownSpanOverride,
        h2: MarkdownSpanOverride,
        h3: MarkdownSpanOverride,
        h4: MarkdownSpanOverride,
        h5: MarkdownSpanOverride,
        h6: MarkdownSpanOverride,
        blockquote: MarkdownSpanOverride,
        ul: MarkdownSpanOverride,
        ol: MarkdownSpanOverride,
        li: MarkdownSpanOverride,
        code: MarkdownSpanOverride,
        pre: MarkdownSpanOverride,
        a: MarkdownSpanOverride,
        img: MarkdownSpanOverride,
        hr: MarkdownSpanOverride,
        br: MarkdownSpanOverride
      }
    }}
  >
    {children}
  </Markdown>
);
export default Marked;
